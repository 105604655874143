var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "js-common-wrap" },
    [
      _c("div", { staticClass: "js-common-head" }, [
        _c("label", [_vm._v("事件列表")]),
        _c(
          "div",
          [
            _c(
              "el-button",
              {
                attrs: { type: "primary", size: "small" },
                on: {
                  click: function ($event) {
                    $event.stopPropagation()
                    return _vm.handleEdit(false, "")
                  },
                },
              },
              [_vm._v("创建事件")]
            ),
          ],
          1
        ),
      ]),
      _c(
        "div",
        { staticClass: "js-common-content" },
        [
          _c(
            "el-table",
            {
              staticClass: "js-table",
              attrs: {
                data: _vm.tableData,
                height: "100%",
                "empty-text": "暂无数据",
              },
            },
            [
              _c("el-table-column", {
                attrs: {
                  prop: "address",
                  label: "地点",
                  width: "160",
                  disable: "",
                },
              }),
              _c("el-table-column", {
                attrs: { prop: "content", label: "事件内容" },
              }),
              _c("el-table-column", {
                attrs: { prop: "createdByName", label: "上报人" },
              }),
              _c("el-table-column", {
                attrs: { prop: "happenTime", label: "发生时间", width: "160" },
              }),
              _c("el-table-column", {
                attrs: { prop: "name", label: "事件名称" },
              }),
              _c("el-table-column", {
                attrs: { prop: "remark", label: "备注" },
              }),
              _c("el-table-column", {
                attrs: { prop: "reportUserName", label: "录入人" },
              }),
              _c("el-table-column", {
                attrs: { prop: "type", label: "事件类型" },
              }),
              _c("el-table-column", {
                attrs: { prop: "urgencyRank", label: "紧急程度" },
              }),
              _c("el-table-column", {
                attrs: { label: "操作" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("i", {
                          staticClass: "el-icon-edit-outline",
                          on: {
                            click: function ($event) {
                              $event.stopPropagation()
                              return _vm.handleEdit(true, scope.row)
                            },
                          },
                        }),
                        _c("i", {
                          staticClass: "el-icon-delete",
                          on: {
                            click: function ($event) {
                              $event.stopPropagation()
                              return _vm.handleDelete(scope.row)
                            },
                          },
                        }),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c("common-pagination", {
        on: { curPageChange: _vm.handleCurPageChange },
        model: {
          value: _vm.pageOptions,
          callback: function ($$v) {
            _vm.pageOptions = $$v
          },
          expression: "pageOptions",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }