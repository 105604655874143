<template>
  <div class="js-common-wrap">
    <div class="js-common-head">
      <label>事件列表</label>
      <div>
        <el-button type="primary" size="small" @click.stop="handleEdit(false,'')">创建事件</el-button>
      </div>
    </div>
    <div class="js-common-content">
      <el-table class="js-table" :data="tableData" height="100%" empty-text="暂无数据">
        <el-table-column prop="address" label="地点" width="160" disable></el-table-column>
        <el-table-column prop="content" label="事件内容"></el-table-column>
        <el-table-column prop="createdByName" label="上报人"></el-table-column>
        <el-table-column prop="happenTime" label="发生时间" width="160"></el-table-column>
        <!-- <el-table-column prop="id" label="事件序列号" width="160" disable></el-table-column> -->
        <el-table-column prop="name" label="事件名称"></el-table-column>
        <el-table-column prop="remark" label="备注"></el-table-column>
        <el-table-column prop="reportUserName" label="录入人"></el-table-column>
        <el-table-column prop="type" label="事件类型"></el-table-column>
        <el-table-column prop="urgencyRank" label="紧急程度"></el-table-column>
        <el-table-column label="操作">
            <template slot-scope="scope">
                <i class="el-icon-edit-outline" @click.stop="handleEdit(true,scope.row)"></i>
                <i class="el-icon-delete" @click.stop="handleDelete(scope.row)"></i>
            </template>
        </el-table-column>
      </el-table>
    </div>
    <common-pagination v-model="pageOptions" @curPageChange="handleCurPageChange"></common-pagination>
  </div>
</template>
<script>
import CommonPagination from '@/components/CommonPagination'
export default {
  name: '',
  components: {
    CommonPagination,
  },
  data() {
    return {
      tableData: [],
      editDialogVisible: false,
      pageOptions:{
        page: 1,
        perPage: 20,
        total: 0
      },
      type: ''
    }
  },
  mounted() {
    this.getList()
  },
  methods: {
    getList(){
      this.$axios({
        method: 'get',
        url: 'api/csp/task/v1/taskEvent/page',
        params: {
          page: this.pageOptions.page,
          perPage: this.pageOptions.perPage
        }
      }).then((response)=>{
        let data = response.data
        if(!data.status){
          this.tableData = data.data.rows
          this.pageOptions.total = data.data.total
        }else{
          this.$message({
            type: 'error',
            message: data.msg,
            duration: 1000
          })
        }
      })
    },
    handleDelete(row){
      this.$axios({
        method: 'delete',
        url: `api/csp/task/v1/taskEvent/${row.id}`,
        params: {
          id: row.id
        }
      })
      this.$router.go(0)
    },
    // 分页
    handleCurPageChange(){
      this.getList()
    },
    handleEdit(flag,row){
      let options = ''
      if(flag){
        options = row
        this.type = 1
      }
      
      this.$router.push({
        path: `/event/eventCreate`,
        query:{
          options: JSON.stringify(options),
          type: this.type
        }
      })
    }
  },
}
</script>
<style lang="scss" scoped>
</style>
